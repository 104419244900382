
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import { FactorUsage } from "../../models/reports/factorusage";
import { AGSREPORT_URL } from "@/models/urls";
import { formatDecimal, formatFinancialAmounts } from "../../utils/format";

export default Vue.extend({
  name: "FactorUsage",
  data: () => ({
    factorUsageData: [] as FactorUsage[],
    isLoading: true,
  }),
  computed: {
    selectedSurvey() {
      return this.$store.getters["agsreport/getYearSelected"];
    },
    headers() {
      return [
        {
          text: "N",
          value: "n",
        },
        {
          text: i18n.t("reports.agsreports.yearselected"),
          value: "surveyYear",
        },
        {
          text: i18n.t("basic.region"),
          value: "region",
        },
        {
          text: `${i18n.t("basic.country")}`,
          value: "country",
        },
        {
          text: "GNI",
          value: "gni",
        },
        {
          text: `${i18n.t("datamanager.patients.total")} FVIII ${i18n.t(
            "reports.agsreports.concentrates"
          )}`,
          value: "fviiiFactor",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: `${i18n.t("datamanager.patients.total")} FIX ${i18n.t(
            "reports.agsreports.concentrates"
          )}`,
          value: "fixFactor",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: `FVIII per Capita`,
          value: "fviiiCapita",
          formatter: (val: number): number | string => formatDecimal(val, 6),
        },
        {
          text: `FIX per Capita`,
          value: "fixCapita",
          formatter: (val: number): number | string => formatDecimal(val, 6),
        },
      ];
    },
  },
  methods: {
    getFactorUsageData(year: number) {
      this.isLoading = true;
      axios
        .get(`${AGSREPORT_URL}/factorusage?year=${year}`)
        .then((res) => {
          this.factorUsageData = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
    exportData() {
      axios
        .get(
          `${AGSREPORT_URL}/factorusage/export?year=${this.selectedSurvey.year}`,
          { responseType: "blob" }
        )
        .then((res) => {
          const fileName = `${i18n.t(
            "navbar.menu.reportsub.ags_reports"
          )} - ${i18n.t("reports.agsreports.factorusage")}`;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "aplication/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    if (this.selectedSurvey.year) {
      this.getFactorUsageData(this.selectedSurvey.year);
    }
  },
  watch: {
    selectedSurvey() {
      this.getFactorUsageData(this.selectedSurvey.year);
    },
  },
});
